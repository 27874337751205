import React from 'react';
import HeaderWatch from './components/HeaderWatch';
import { useHistory, useParams } from 'react-router-dom';
import LessonsSliders from './components/LessonsSliders';
import { Row, Col } from 'react-bootstrap';
import OtherTeacherList from '../../components/OtherTeacherList/OtherTeacherList';
import './style.css';
import { useAuth } from '../../contexts/AuthContext';

export default function Watching() {
  let history = useHistory();
  const { lesson } = useParams();
  const { inLogin } = useAuth();

  if (!inLogin) {
    localStorage.setItem('ws-currentPath', window.location.pathname);
    history.push('/login');
  } else localStorage.removeItem('ws-currentPath');
  return (
    <div>
      <section className="iq-breadcrumb-one1 bg-watch-header pb-0" style={{ background: '#14161A', paddingBottom: '50px' }}>
        <HeaderWatch slug={lesson} />
      </section>
      <LessonsSliders />

      {/* <section>
        <div className="mt-1 mt-md-5">
          <Row className="align-items-center">
            <Col sm="12" fluid>
              <div className="text-center iq-breadcrumb-two">
                <h6 className=" text-primary mb-4 text-uppercase">İlgini Çekebilecek Eğitmenler</h6>
                <h3 className="title mb-5">Bu Eğitimi İzleyenler Bunları İzledi</h3>
              </div>
            </Col>
          </Row>
          <OtherTeacherList count={5} />
        </div>
      </section> */}
    </div>
  );
}
