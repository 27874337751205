import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
// Partials
import HeaderStyle1 from '../../components/partials/backend/headerstyle/headerstyle1';
import FooterStyle from '../../components/partials/backend/footerstyle/footerstyle';

// Router Component
import Layout1Route from '../../router/layout1-route';
import { useAuth } from '../../contexts/AuthContext';
import SignUpFixed from '../../components/SignupMobil';
import LeftMenu from '../../components/LeftMenu/LeftMenu';
import RightMenu from '../../components/RightMenu/RightMenu';

const Layout1 = () => {
  const { inLogin, showMenu, showRightMenu } = useAuth();
  const [inloginPage, setInloginPage] = useState(false);

  const paraps = useParams();
  const backToTop = document.getElementById('back-to-top');

  if (backToTop !== null && backToTop !== undefined) {
    document.getElementById('back-to-top')?.classList.add('animated', 'fadeOut');

    window.addEventListener('scroll', (e) => {
      if (document.documentElement.scrollTop > 50) {
        document.getElementById('back-to-top')?.classList.remove('fadeOut');
        document.getElementById('back-to-top')?.classList.add('fadeIn');
      } else {
        document.getElementById('back-to-top')?.classList.remove('fadeIn');
        document.getElementById('back-to-top')?.classList.add('fadeOut');
      }
    });

    document.querySelector('#top').addEventListener('click', (e) => {
      e.preventDefault();
      window.scrollTo({ top: 0, behavior: 'smooth' });
    });
  }
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    if (
      window.location.pathname === '/login' ||
      window.location.pathname === '/sign-up' ||
      window.location.pathname === '/set-password' ||
      window.location.pathname === '/select-plan' ||
      window.location.pathname === '/set-card' ||
      window.location.pathname.includes('reset-password')
      // window.location.pathname?.indexOf('reset-password')
    )
      setInloginPage(true);
    else setInloginPage(false);

    return {};
  }, [paraps]);

  return (
    <>
      {/* <div id="back-to-top">
        <Link className="top" to="#" id="top">
          {" "}
          <i className="fa fa-angle-up"></i>{" "}
        </Link>
      </div> */}
      {!showMenu && !showRightMenu && (
        <div className="wraper">
          <HeaderStyle1 />
          <div className="content-page" id="content-page">
            <Layout1Route />
          </div>
        </div>
      )}
      {/* {!inLogin && !inloginPage && <SignUpFixed />} */}
      <FooterStyle />
      {showMenu && <LeftMenu />}
      {showRightMenu && <RightMenu />}
    </>
  );
};

export default Layout1;
